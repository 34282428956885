import { useState, useEffect } from "react"
import React from 'react'
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import 'moment/locale/es';
import moment from 'moment';
import { ConfigServer } from "../../data/config";



export const useCashGlobal = () => {

    //filter
    const [filter, setFilter] = useState("1");
    const [dataGlobal, setDataGlobal] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [TotalAmountCapture, setTotalAmountCapture] = useState(0);
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam] = useSweetAlert();
    //Amount Total Branch
    const [branch, setBranch] = useState([]);
    const [amountTotal, setAmountTotal] = useState([]);
    //Preview Global
    const [typeModal, setTypeModal] = useState("ViewGlobal");
    const [modal, setModal] = useState(false);
    //local storage
    let id_branch = localStorage.getItem('branchId');
    //cardInfo
    const [cardInfo, setCardInfo] = useState([]);
    const [cardInfoKey, setCardInfoKey] = useState([]);
    //Preview Global Branch
    const [typeModalB, setTypeModalB] = useState("BrachC");
    const [modalB, setModalB] = useState(false);
    //Preview Global Branch
    const [typeModalS, setTypeModalS] = useState("Search");
    const [modalS, setModalS] = useState(false);
    //historic Global
    const [dataHistoric, setDataHistoric] = useState([]);


    const toggle = () => {
        setModal(!modal);
    }

    const toggleB = () => {
        setModalB(!modalB);
    }

    const toggleS = () => {
        setModalS(!modalS);
    }
    //#region formValues
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        date1: "",
        date2: "",
        capture_amount: "",
        comments: ""
    });

    const {
        date1,
        date2,
        capture_amount,
        comments
    } = formValues;
    //#endregion formValues

    //#region validations
    const [validationsSG, setvalidationsSG] = useState({
        capture_amount: false,
    });
    //#endregion validations

    //filter type
    const handleChangeTypeClient = (typeClient) => {
        setFilter(typeClient)
    }

    //#region information Cash flow global
    const getInformationGlobal = async () => {

        let _date1 = (date1 === '') ? "" : moment(date1).format("YYYY-MM-DD");
        let _date2 = (date2 === '') ? "" : moment(date2).format("YYYY-MM-DD");

        setLoadingData(false);

        let listTmp = await handleRequest("GET", `WorkOrder/ListGlobalCash/${_date1}/${_date2}`, "CashGlobal");

        if (listTmp !== null) {
            setLoadingData(true);
            let _array = [];
            let _amounTotal = 0;
            let _amountCapture = 0;

            listTmp.forEach((_info, _key) => {
                _amounTotal += Math.round(parseFloat(_info.amount));
                _amountCapture += Math.round(parseFloat(_info.captureAmount));

                _array.push({
                    id_cash_flow: _info.id_cash_flow,
                    id_branch: _info.id_branch,
                    branch: _info.branch,
                    userCapture: _info.userCapture,
                    pay_method: _info.pay_method,
                    amount: formatter.format(Math.round(_info.amount)),
                    captureAmount: formatter.format(Math.round(_info.captureAmount)),
                    amountT: Math.round(_info.amount),
                    amountC: Math.round(_info.captureAmount),
                    comment: _info.comments,
                    fechCashFlow: _info.fechCashFlow
                })
            });
            ////console.log(_array);
            setDataGlobal(_array);
            setTotalAmount(_amounTotal);
            setTotalAmountCapture(_amountCapture);
        }
    }
    //#region formatter price $ 
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    //#endregion formatter price $

    //#region calculatePrice 

    useEffect(() => {
        if (dataGlobal.length >= 1) {
            let _copy = dataGlobal;
            let _nuevoObjeto = {};
            let nuevoObjeto = {};
            let indexObject = [];
            
            _copy.forEach(x => {
                const _valid_Amount = x.amount.replace(/[^0-9.-]+/g, ""); 
                const _valid_capture_Amount = x.captureAmount.replace(/[^0-9.-]+/g, ""); 
    
                const _valid_amounts = _valid_Amount === _valid_capture_Amount;
    
                if (!_nuevoObjeto.hasOwnProperty(x.branch)) {
                    _nuevoObjeto[x.branch] = {
                        Amount: 0,
                        AmountCapture: 0,
                    }
                    indexObject.push(x.branch);
                }
                _nuevoObjeto[x.branch].Amount += Math.round(x.amountT);
                _nuevoObjeto[x.branch].AmountCapture += Math.round(x.amountC);
    
                if (!nuevoObjeto.hasOwnProperty(x.branch)) {
                    nuevoObjeto[x.branch] = {
                        secciones: []
                    };
                }
                nuevoObjeto[x.branch].secciones.push({
                    nombre: x.comment,
                    user: x.userCapture,
                    fech: x.fechCashFlow,
                    isEqual: _valid_amounts 
                });
            });
    
            setAmountTotal(_nuevoObjeto);
            setBranch([...indexObject]);
            setCardInfo(nuevoObjeto);
            setCardInfoKey(indexObject);
        } else {
            setAmountTotal([]);
            setBranch([]);
            setCardInfo([]);
            setCardInfoKey([]);
        }
    }, [dataGlobal]);
    
    //#endregion calculatePrice
    //#endregion information Cash flow global

    //#region detail User
    const handleGetDetail = async () => {
        let listTmp = await handleRequest("GET", "WorkOrder/DetailCashFlowGlobal", 'global');

        if (listTmp !== null) {
            setDataHistoric(listTmp);
        }
    }
    //#endregion detail User

    //#region detailModal
    const previewGlobalCash = async (nim) => {
        setTypeModal("ViewGlobal");
        toggle();
    }

    const previewGlobalCashB = async (nim) => {
        setTypeModalB("BrachC");
        toggleB();
    }

    const previewGlobalCashS = async (nim) => {
        setTypeModalS("Search");
        handleGetDetail()

        toggleS();
    }
    //#endregion detailModal

    //#region Create Global Cash
    const saveCashFlowGlobal = async () => {

        if (validationCashGlobal()) {
            return;
        }

        let _body = {
            comments: formValues.comments,
            total_amount: TotalAmount,
            amount_deposit: formValues.capture_amount,
            is_global: true,
            id_branch: id_branch
        }

        const _response = await handleRequest("POST", "CashFlow/CreateGlobal", "Crear Cierre Global", _body);
        if (_response !== null) {
            createSweet("success", "success", "Se Genero correctamente el cierre global");
            updateGlobal();
        }
    }
    //#endregion Create Global Cash

    //#region update
    const updateGlobal = () => {
        handleUpdateValues({
            ...formValues,
            date1: "",
            date2: "",
            capture_amount: "",
            comments: ""
        })
        setDataGlobal([]);
        setTotalAmount([]);
        setTotalAmountCapture([]);
        setCardInfo([]);
    }
    //#endregion update

    const printTicket = async (id_cash_flow) => {

        if (id_cash_flow > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/CashFlow/PrintTicket/" + id_cash_flow, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }

                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                })
        }
    }

    //#region validations
    const validationCashGlobal = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.capture_amount === "") {
            newValidations = {
                ...newValidations,
                capture_amount: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                capture_amount: false,
            };
        }
        setvalidationsSG(newValidations);
        return statusValidation;
    }
    //#endregion validations

    //#region request
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    return {
        //filter cash flow global
        filter, handleChangeTypeClient, loadingData,
        //date
        date1, date2, handlePickerValues, getInformationGlobal, dataGlobal,
        //Amount Total Branch
        branch, amountTotal, formatter, TotalAmount, TotalAmountCapture,
        //modal Preview Global
        typeModal, modal, toggle, previewGlobalCash,
        //capture amount
        handleInputChange, capture_amount, formValues, comments,
        //cretae
        saveCashFlowGlobal,
        //cardInfo
        cardInfo, cardInfoKey,
        //modal Preview
        previewGlobalCashB, toggleB, typeModalB, modalB,
        //validations
        validationsSG,
        //modal Search
        toggleS, typeModalS, modalS, previewGlobalCashS,
        //
        dataHistoric,printTicket

    }
}
