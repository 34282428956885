import React, { useState } from 'react';
import { useForm } from '../forms/useForm';
import { useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { Code500 } from '../../constant/globalMessage';
import { useHistory,useParams } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import 'moment/locale/es';
import moment from 'moment';
import * as XLSX from 'xlsx';
import ConfigDB from '../../data/customizer/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;


export const useBankingDeposits = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const [listMovements, setListMovements] = useState(null);
    const [indicatorAmount, setIndicatorAmount] = useState(null);
    const [indicatorCancell, setIndicatorCancell] = useState(null);
    const [indicatorPatient, setIndicatorPatient] = useState(null);
    const [series, setSeries] = useState([]);
    const [indicatorAmountInvoice, setIndicatorAmountInvoice] = useState(null);
    const [listMovementsInvoice, setListMovementsInvoice] = useState(null);
    const [listMovementsCalcule, setListMovementsCalcule] = useState([]);
    const [disabledSaveButton, setDisabledSaveButton] = useState(false);
    const [validNotification, setValidNotification] = useState(false)
    const [listReceived, setListReceived] = useState(null);
    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [listFullyPayd, setListFullyPayd] = useState(null);
    const [indicadorFullyPaid, setIndicadorFullyPaid] = useState(null);
    const [series2, setSeries2] = useState([]);
    const [series3, setSeries3] = useState([]);
    const [series4, setSeries4] = useState([]);
    const [serieDonut, setSetserieDonut] = useState([]);
    const [serieDonutCancell, setSetserieDonutCancell] = useState([]);
    const [series5, setSeries5] = useState([]);
    const [indicadorCredit, setIndicadorCredit] = useState(null);
    const [listFilter, setListFilter] = useState(null);
    const [filterEfectivo, setFilterEfectivo] = useState(false);
    const [countPublic, setCountPublic] = useState(0);
    const [countGeneral, setCountGeneral] = useState(0);
    const [filterTC, setFilterTC] = useState(false);
    const [filterTD, setFilterTD] = useState(false);
    const [filterTransferencias, setFilterTransferencias] = useState(false);
    const [dataFilter, setDataFilter] = useState([])
    const [brach, setBrach] = useState([]);
    const [sizeCard, setSetsizeCard] = useState(false)
    const [loadingFinance, setLoadingFinance] = useState([])

  

    //*VARIABLES LOCAL STORAGE
    let _user = localStorage.getItem('nameUser');

    //*FILTER DATE
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();

    const startDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-01`;
    const endDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-${currentDay <= 9 ? '0' + currentDay : currentDay}`;

    const [formQuery, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        start_date: startDate,
        end_date: endDate,
        id_branch: 0,
        detail_invoice: true,
        received: "",
        delivere: "",
        amount: 0,
        observation:"",
        date_1: "",
        date_2: "",
        one: false,
        range: false,
        type_action: "A",
        type_company: null

    });

    const { start_date, end_date, id_branch,received,delivere,amount,observation, date_1,date_2,one,range,type_action,type_company} = formQuery;

     //*VALIDATION SEND RECEIVED
     const [validationsSG, setvalidationsSG] = useState({
      amount: false,
      received: false,
      delivere: false
    });

        //*CONFIGURATION FINANCE
        let _valid_Finance = localStorage.getItem('finance');
        let _finance2 = localStorage.getItem('finance_view');
        let _validationFinance = (_valid_Finance === "True" &&  _finance2 === "False") ? true : false

    //*METHOD REPORT BANKING     
    useEffect(async () => {
        await GetReportDeposits();
        await GetDeposits();
        await  getBranches();
    }, []);

    useEffect(async () => {
      if (_valid_Finance === "True") {
       // if (_finance2 === "False") {
          await GetReportDepositsFinance();

        //}
    }
    
 
  }, [_valid_Finance]);

  
    //*LIST BRANCH
    const getBranches = async () => {
      let _listTemp = await _handleRequest("GET", "Branch/List", "Sucursal");
      if (_listTemp !== null) {
          let _newBranches = [];
          _listTemp.forEach((_obj) => {
              _newBranches.push({
                  id_branch: _obj.id_branch,
                  name: _obj.name
              });
          });
          setBrach(_newBranches);
      }
  }


    //*HANLDLE
    const handleTypeCompany = (_typeClient) => {

      handleUpdateValues({
          ...formQuery,
          type_company: _typeClient
      });
  }


    //*VALIDATION TYPE CLIENT
    const handleChangeTypeAction = (typeInvoice) => {
      handleUpdateValues({
          ...formQuery,
          type_action: typeInvoice
      });
    }

    //*REPORT CASH AND INVOICE

    const [totalDepositSend, setTotalDepositSend] = useState(0);
    const [listDetailBranch, setListDetailBranch] = useState([]);
    const [listDetailBranchInvoice, setListDetailBranchInvoice] = useState([]);
    const [amountDesposit, setAmountDesposit] = useState(0);
    const [arrayDeposits, setArrayDeposits] = useState([]);

    const [amountFinance, setAmountFinance] = useState(0);
    const [detailBranch, setDetailBranch] = useState([])

    // ? DETAIL PAYMENT COMPLEMENT
    const [dataComplement, setDataComplement] = useState([]);
    const [totalComplement, setTotalComplement] = useState(0);
    const [isComplement, setIsComplement] = useState(false);

    //*CONFIGURATION 

    const GetReportDepositsFinance = async () => {

      let _method  = '';
      setLoadingFinance(true);

      _method = `ProgrammingSerie/ListProgrammingAmount2/${start_date}/${end_date}`;
     
      const request = await _handleRequest("GET", _method, "Reporte Bancario");

      if (request !== null) {
        setLoadingFinance(false);
        //!VALIDATIONS 
        request.forEach((_find,_key) => {
          setDetailBranch( _find.commercialBranchTreeView)  
        });
        setAmountFinance(request.total_amount)
      }
  }

    const GetReportDeposits = async () => {

        let _method  = '';
        

        if (type_company == null ) {
          _method = `Reports/GetReportBanking/${start_date}/${end_date}?detail_invoice=true&detail_paid=true&cancel_invoice=true&pending=true`;
        }
        else if(type_company ===  1) {
          
          _method = `Reports/GetReportBanking/${start_date}/${end_date}?detail_invoice=true&detail_paid=true&cancel_invoice=true&pending=true&isType=1`;      
        }
        else if(type_company ===  2) {
          
          _method = `Reports/GetReportBanking/${start_date}/${end_date}?detail_invoice=true&detail_paid=true&cancel_invoice=true&pending=true&isType=2`;
        }

      
        if (id_branch !== 0 && id_branch.value !== undefined) {
            _method = _method + `&id_branch=${id_branch.value}`;
        }

        const request = await _handleRequest("GET", _method, "Reporte Bancario");

        if (request !== null) {
          let _valid2 = [];
          let _valid1 = [];
          let _valid2_invoice = [];
          let _valid1_invoice = [];
          let _valid2_fully = [];
          let _valid1_fully = [];
          let _valid2_credit = [];
          let _valid1_credit = [];
          let _valid3 = [];
          let _valid4 = [];

          let _valid = request.reportListMovement.map(_find => `"${_find.amount}"`);
          _valid2 = _valid.map(_find => _find.replace(/"/g, ''));

          _valid1 = request.reportListMovement.map(_find => _find.pay_method); 

          const seriesData = _valid2.map(Number);
    
          const series = [{
             name: _valid1 && _valid1.length > 0 ? _valid1[0] : "", 
              data: seriesData,
          }];

          let _validInvoice = request.reportListInvoice.map(_find => `"${(_find.amount)}"`);
          _valid2_invoice = _validInvoice.map(_find => _find.replace(/"/g, ''));

          _valid1_invoice = request.reportListInvoice.map(_find => _find.pay_method); 

          const seriesDataInvoice = _valid2_invoice.map(Number);


          const seriesInvoice = [{
            name: _valid1_invoice && _valid1_invoice.length > 0 ? _valid1_invoice[0] : "", 
            data: seriesDataInvoice,
          }];

          let _validFully = request.reportListFullyPaid.map(_find => `"${_find.amount}"`);//
          _valid2_fully = _validFully.map(_find => _find.replace(/"/g, ''));

          _valid1_fully = request.reportListFullyPaid.map(_find => _find.pay_method); 

          const seriesDataFully = _valid2_fully.map(Number);

          let _validCredit = request.reportListCredit.map(_find => `"${_find.amount}"`);//
          _valid2_credit = _validCredit.map(_find => _find.replace(/"/g, ''));

          _valid1_credit = request.reportListInvoiceCancell.map(_find => _find.pay_method); 

          const seriesDataCredit = _valid2_credit.map(Number);


          //*valid credit
          let _validCreditP = request.reportListCredit.map(_find => `"${_find.amount}"`);//
          _valid4 = _validCreditP.map(_find => _find.replace(/"/g, ''));

          _valid3 = request.reportListCredit.map(_find => _find.pay_method); 

          const seriesDataCreditP = _valid4.map(Number);

          const seriesFully = [{
            name: [`Monto`],
            data: seriesDataFully,
          }];

          const combinedSeries = [
            {
              name: _valid1 && _valid1.length > 0 ? _valid1[0] : "",
              data: seriesData,
            },
            {
              name: _valid1_invoice && _valid1_invoice.length > 0 ? _valid1_invoice[0] : "",
              data: seriesDataInvoice,
            },
          ];
        
          const finalSeries = combinedSeries.map((item, index) => {
            return {
              name: index === 0 ? `Total Cobrado` : `Total Facturado`,
              data: item.data,
            };
          });

          //*credit
          const combinedSeries2 = [
            {
              name: _valid1 && _valid1.length > 0 ? _valid1[0] : "",
              data: seriesDataFully,
            },
            {
              name: _valid1_invoice && _valid1_invoice.length > 0 ? _valid1_invoice[0] : "",
              data: seriesDataCreditP,
            },
          ];
        
          const finalSeries2 = combinedSeries2.map((item, index) => {
            return {
              name: index === 0 ? `Total Cobrado` : `Total Facturado`,
              data: item.data,
            };
          });


          //*credit
          let _totalDeposits = 0;

          let _array = [];
          request.reportListMovement.forEach(_find => {
              let _var = Math.round(_find.amount);
              console.log("🚀 ~ GetReportDeposits ~ _find:", _find)

              switch (_find.id_pay_method) {
                  case 2: 
                  case 4:
                  case 8:
                      _totalDeposits += _var;
                      break;
              }
              console.log("🚀 ~ GetReportDeposits ~ _totalDeposits:", _totalDeposits)

          
              _array.push({
                  amount: _var ,
                  id_pay_method: _find.id_pay_method,
                  pay_method: _find.pay_method
              });
          });


          //*GROUP BRANCH CASH FLOW
          let groupedByBranch = {};

          request.reportListBranch.forEach(_find => {

            if (_find.id_pay_method === 30 || _find.id_pay_method === 31 ) {
              return;
            }//!IGNORE
          
              if (!groupedByBranch[_find.id_branch]) {
                  groupedByBranch[_find.id_branch] = [];
              }
              groupedByBranch[_find.id_branch].push(_find);
          });


          //*GROUP BRANCH CASH FLOW
          let groupedByBranchInvoice = {};

          request.reportListBranchInvoice.forEach(_find => {
            if (!groupedByBranchInvoice[_find.id_branch]) {
                groupedByBranchInvoice[_find.id_branch] = [];
              }
              groupedByBranchInvoice[_find.id_branch].push(_find);
            });

          //*VALID AMOUNT
          let _amounts = 0
          let _sump = 0
          let _totalPayment = 0;



            request.reportDeposits.forEach((_find) => {
             _amounts += _find.amount
             console.log("🚀 ~ request.reportDeposits.forEach ~ _amounts:", _amounts)
            })

          

            // ? PAYMENTS
            request.reportPaymentsComplement.forEach((_find) => {
              _totalPayment += _find.payment_amount
             })

               //!SUM TOTAL DEPOSITS
               _sump = _amounts += _totalDeposits +=_totalPayment
               console.log("🚀 ~ GetReportDeposits ~ _sump:", _sump)

            setAmountDesposit(_amounts)
            setSeries(finalSeries);
            setListMovements(_array);
            setIndicatorAmount(request.total_amount);
            setIndicatorCancell(request.total_invoice);
            setIndicatorPatient(request.total_patient);
            setIndicatorAmountInvoice(request.total_amountInvoice)
            setListMovementsInvoice(request.reportListInvoice)
            setListFullyPayd(request.reportListFullyPaid)
            setIndicadorFullyPaid(request.total_fully)
            setSeries2(finalSeries2)
            setSeries3(_valid1_fully)
            setSeries4(_valid1_invoice)
            setSetserieDonut(seriesDataFully)
            setSetserieDonutCancell(seriesDataCredit)
            setSeries5(_valid1_credit)
            setIndicadorCredit(request.total_amountCredit)
            setTotalDepositSend(_sump)
            setListDetailBranch(groupedByBranch)
            setListDetailBranchInvoice(groupedByBranchInvoice)
            setArrayDeposits( request.reportDeposits)
            setDataComplement(request.reportPaymentsComplement)
            setTotalComplement(_totalPayment)
            GetFilterInvoices()
            
            
        }
    }

    //*CHART CASH AND INVOICE

    const CurrentlyDeposits2 = {
      series: series,
      options: {
        chart: {
          height: 310,
          type: 'area',
          toolbar: {
            show: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'category',
          low: 0,
          offsetX: 0,
          offsetY: 0,
          show: false,
          categories: series4,
          labels: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisBorder: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 3,
          colors: "#ffffff",
          strokeColors: [primary, "#dc3545"],
          hover: {
            size: 6,
          }
        },
        yaxis: {
          low: 0,
          offsetX: 0,
          offsetY: 0,
          show: false,
          labels: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisBorder: {
            low: 0,
            offsetX: 0,
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: -15,
            top: -40
          }
        },
        colors: [primary,  "#dc3545"],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.5,
            stops: [0, 80, 100]
          }
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            format: 'MM'
          },
        },
      }
    }

    //*REPORT CALCULATE HELEN

    const GetReportPublic = async () => {

      setLoading(true);

      let _method2 = `Reports/GetReportGeneralPub/${start_date}/${end_date}?branch=0`;
  
      const request = await _handleRequest("GET", _method2, "Reporte Bancario");
  
      if (request != null) {
        setLoading(false);

        const groupedData = request.reduce((groups, item) => {
          const key = JSON.stringify(item.pay_methods);
          groups[key] = groups[key] || { pay_methods: item.pay_methods, totalAmount: 0 };
      
          if (Array.isArray(item.total) && item.total.length > 0) {
              groups[key].totalAmount += item.total[item.total.length - 1];
          } else if (typeof item.total === 'number') {
              groups[key].totalAmount += item.total;
          }
          return groups;
      }, {});
      
          const groupedArray = Object.values(groupedData);
          setListMovementsCalcule(groupedArray)
      }
    }

    //*CLEAN REPORT 

    const handleClearValues = () => {
      handleUpdateValues({
        received: "",
        delivere: "",
        amount: 0,
        observation:"",
        date_1: "",
        date_2: ""

      })
    }

    //*SAVE CALCULATE RECEIVED

    const saveReceived = async () => {

      if (validationReceived()) {
        return;
       }

      setDisabledSaveButton(true);

      let _validFech =  formQuery.date_2 === "" ?  formQuery.date_1 : formQuery.date_1;
      let _validFech2 =  formQuery.date_2 != "" ?  formQuery.date_2 : formQuery.date_1;

      createSweet("info", "info", "Recopilando información, para validar!");

      let _body = {
        user_received: formQuery.received,
        user_delivere: formQuery.delivere,
        amount: formQuery.amount,
        observation: formQuery.observation,
        date_1:_validFech2,
        date_2: _validFech
      }
      
      const listTmp = await _handleRequest("POST", `CashFlow/CreateDeposits`, "Facturación", _body);

      if (listTmp !== null) {
          createSweet("create", "info", "Exito!", "Se genero el deposito con exito!");
          setDisabledSaveButton(false);
      }
      else {
         setDisabledSaveButton(false);

      }
      handleClearValues();

    }

    //*ONCHANGE CALENDER

    const onChange = (newDate) => {
      setDate(newDate);
      setSelectedDate(newDate);

    };
  
    //*VIEW RECEIVED

    const GetDeposits = async () => {
      let _valid = moment(date).format("YYYY-MM-DD");
    
      let _method2 = `Reports/GetReportDeposits/${_valid}`;
    
      const request = await _handleRequest("GET", _method2, "Deposito bancario");
      
    
        if (request == null) {
          setValidNotification(false);
        }
        else{
          setValidNotification(true);
          setListReceived(request)

        }
    };

    useEffect(() => {
  
      if (validNotification == true && validNotification == false ) {
        setSelectedDate(date);
      }

    }, [validNotification, date]);


    const tileClassName = ({ date }) => {

        const isSelected = selectedDate && moment(date).isSame(selectedDate, 'day');
        return isSelected ? (validNotification ? 'selected-valid' : 'selected-invalid') : '';
    };


    //*CHART FULLY PAID AND CREDIT

    const Monthlysales = {
      series: series2,
      options: {
        xaxis: { // Coloca la configuración del eje x primero
          type: 'string',
          categories: series3,
        },
        chart: {
          height: 180,
          type: 'line', // Usa un gráfico de línea
          toolbar: {
            show: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 0
        },
        yaxis: {
          show: false
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        legend: {
          show: true,
          position: 'top'
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            top: -50,
            bottom: -40,
          }
        },
        fill: {
          type: 'gradient',
          opacity: [1, 0.4, 0.25],
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 1,
            gradientToColors: ['#a26cf8', '#a927f9', primary],
            opacityFrom: [1, 0.4, 0.25],
            opacityTo: [1, 0.4, 0.25],
            stops: [30, 100],
            colorStops: []
          },
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560']
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
        markers: { // Agrega marcadores solo si hay un solo punto de datos
          size: 10,
          colors: series2.every(data => data.length === 1) ? ['#FF5733', '#33FF57', '#FF4560', '#FFC300'] : ['#FF5733', '#00E396', '#FEB019', '#FF4560'],
          strokeWidth: 0,
          hover: {
            sizeOffset: 0
          }
        }
      }
    };


    const ChartPaymentConditions = {
      series: serieDonut,
      options: {
        chart: {
          width: 680,
          type: 'donut',
        },
        labels: series3,
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return "$ " + number_format(w.globals.seriesTotals.reduce((a, b) => {
                      return (a + b)
                    }, 0), 2)
                  }
                },
              }
            },
          }
        },
        dataLabels: {
          enabled: true
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          },
          show: true,
          position: "right",
          offsetY: 20 
        },
        title: {
          //  text: 'Metodos de recuperación',
          align: 'right',
          margin: 10 
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
              offsetY: 10 
            },
            title: {
              margin: 10 
            }
          }
        }],
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val
            }
          },
        },
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        // Agrega función para controlar la animación al pasar el cursor sobre la gráfica
        events: {
          mouseEnter: function(chartContext, config) {
            // Cambia el color de la gráfica al pasar el cursor
            chartContext.updateOptions({
              colors: ['#FF5733', '#33FF57', '#338AFF', '#FFC300']
            });
          },
          mouseLeave: function(chartContext, config) {
            // Restaura el color original de la gráfica al salir el cursor
            chartContext.updateOptions({
              colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560']
            });
          }
        }
      }
    };


    //*CHART INVOICE CANCELL

    const ChartPaymentCancell = {
      series: serieDonutCancell,
      options: {
        chart: {
          width: 680,
          type: 'donut',
        },
        labels: series5,
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return "$ " + number_format(w.globals.seriesTotals.reduce((a, b) => {
                      return (a + b)
                    }, 0), 2)
                  }
                },
              }
            },
          }
        },
        dataLabels: {
          enabled: true
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          },
          show: true,
          position: "right",
          offsetY: 20 
        },
        title: {
          //  text: 'Metodos de recuperación',
          align: 'right',
          margin: 10 
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
              offsetY: 10 
            },
            title: {
              margin: 10 
            }
          }
        }],
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val
            }
          },
        },
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        // Agrega función para controlar la animación al pasar el cursor sobre la gráfica
        events: {
          mouseEnter: function(chartContext, config) {
            // Cambia el color de la gráfica al pasar el cursor
            chartContext.updateOptions({
              colors: ['#FF5733', '#33FF57', '#FF4560', '#FFC300']
            });
          },
          mouseLeave: function(chartContext, config) {
            // Restaura el color original de la gráfica al salir el cursor
            chartContext.updateOptions({
              colors: ['#FF4560', '#00E396', '#FEB019', '#FF4560']
            });
          }
        }
      }
    };


    //*FILTER INVOICES
    const GetFilterInvoices = async (_valid) => {

      let  _valid2 =  _valid === undefined ?  `Reports/GetReportFilterInvoices/${start_date}/${end_date}` :  _valid === 1 ?  `Reports/GetReportFilterInvoices/${start_date}/${end_date}?id_branch=1` : `Reports/GetReportFilterInvoices/${start_date}/${end_date}?id_branch=2`

      let _method = _valid2
      if (id_branch !== 0 && id_branch.value !== undefined) {
          _method = _method + `&id_branch=${id_branch.value}`;
      }

      const request = await _handleRequest("GET", _method, "Filtro");

      if (request !== null) {

        let _totalPublic = 0;
        let _totalPrivate = 0;
        let paymentMethodsAmounts = [];


        request.reportFiltersInvoice.forEach(_find => {

          if (_find.isGeneralPublic) {
            _totalPublic += _find.total_i;
          }
          else {
             _totalPrivate += _find.total_i;
          }     

          let existingItem = paymentMethodsAmounts.find(item => item.id_pay_method === _find.id_pay_method);
          if (existingItem) {
            existingItem.total_i += _find.total_i
          } else {
            paymentMethodsAmounts.push({
              id_pay_method: _find.id_pay_method,
              total_i: _find.total_i
            });
          }
        });

        setDataFilter(paymentMethodsAmounts)
        setListFilter(request.reportFiltersInvoice)
        setCountGeneral(_totalPublic)
        setCountPublic(_totalPrivate)   
      }
  }

  //*EXPORT EXCEL 
     const MakeExcelInvoices = ( outValidity ) => {
    let _report = [];

     listFilter?.length > 0 && (
      ( filterEfectivo || filterTC || filterTD  || filterTransferencias
              ? listFilter
                .filter(_filter => {
                  const lastPaymentMethod = _filter.id_pay_method;
                    if ((filterEfectivo && lastPaymentMethod === 1) ||
                    (filterTC && lastPaymentMethod === 2) ||
                    (filterTD && lastPaymentMethod === 4) ||
                    (filterTransferencias && lastPaymentMethod === 8)) {
                        return true;
                    }
                  
                })
            : listFilter
        ).map((_order, _index) => (
            _report.push({
                "serie y Folio" : _order.serieFolio,
                "Fecha": _order.dateCreated,
                "Tipo de factura": _order.isGeneralPublic === false ? "Paciente" : "Global",
                "RFC": _order.rfc,
                "UUID": _order.uuid,
                "Total": _order.total_i,
                "Estatus": _order.statuaInvoice,
                "Metódo de Pago": 
                (
                    (() => {
                        const lastPaymentMethod = _order.id_pay_method;

                        switch (lastPaymentMethod) {
                            case 1:
                                return "Efectivo";
                            case 2:
                                return "Tarjeta de Crédito";
                            case 4:
                                return "Tarjeta de Débito";
                            case 8:
                                return "Transferencia";
                            default:
                                return "Crédito";
                        }
                    })()
                ),
                "Fecha de Inicio": _order.isGeneralPublic === false ? "----" : _order.date_1 ,
                "Fecha de Fin": _order.isGeneralPublic === false ? "----" : _order.date_2 ,

            })
        ))
    )  
        const worksheet = XLSX.utils.json_to_sheet(_report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Concentrado_De_Particulares.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Para Internet Explorer
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            // Para otros navegadores
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
      }
    }


    //*CHART BRANCHS
    const ChartPaymentConditionsTable = {
      options: {
          chart: {
              type: 'donut',
              width: 40,
              height: 40,
              sparkline: {
                  enabled: true
              }
          },
          labels: ["Crédito", "Contado", "Descuento","precios"],
          stroke: {
              width: 1
          },
          tooltip: {
              fixed: {
                  enabled: false
              },
          }
      },
    }

    //*SIZE CARDS 

      const toggleSize = () => {
        setSetsizeCard(!sizeCard);
      };

    //*CHART DEPOSITS 
    const ChartPaymentConditionsTable1 = {
      options: {
          chart: {
              type: 'donut',
              width: 40,
              height: 40,
              sparkline: {
                  enabled: true
              }
          },
          labels: ["Crédito"],
          stroke: {
              width: 1
          },
          tooltip: {
              fixed: {
                  enabled: false
              },
          }
      },
     }
    //* END CHARTS DEPOSITS

    //*TYPE METHOD
    const handleActions = async () => {
      if (_valid_Finance === "True") {
        if (_finance2 === "False") {
          await GetReportDepositsFinance();
          await GetReportDeposits();
        }
        else {
          await GetReportDeposits();
        }
        }
        else {
         await  GetReportDeposits();
        }

    }


    //*REPORT FINANCE

    const MakeExcelFinance = (outValidity) => {
      if (outValidity) {
        let totalCollected = 0;
        let totalRecovered = 0;
        let totalGlobal = 0;
    
        const rows = detailBranch.flatMap((_branch) =>
          _branch.children.map((_child, _index) => {
            const _branchDetails =
              _child.id &&
              listDetailBranch[_child.id].filter(
                (_detail) =>
                  _detail.id_branch === _child.id && _detail.id_pay_method === 1
              );
    
            const _detail = _branchDetails.find(
              (_find) => _find.id_branch === _child.id
            );
            const _total = _detail.amount - _child.amount_collected;
    
            totalCollected += _child.amount_collected;
            totalRecovered += _detail.amount;
            totalGlobal += _total;
          })
        );
    
        const _report = [
          {
            "Globales": "", 
            Recolectado: `$${number_format(totalCollected, 2)}`, 
            Recuperado: `$${number_format(totalRecovered, 2)}`,
            Total: `$${number_format(totalGlobal, 2)}`, 
          },
        ];
    
        const worksheet = XLSX.utils.json_to_sheet(_report, { header: ['Totales Globales', 'Recolectado', 'Recuperado', 'Total'] });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
    
        const dataBlob = new Blob(
          [excelBuffer],
          {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }
        );
    
        const fileName = 'Concentrado_De_Particulares.xlsx';
    
        if (
          typeof window !== 'undefined' &&
          window.navigator &&
          window.navigator.msSaveOrOpenBlob
        ) {
          // Para Internet Explorer
          window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
          // Para otros navegadores
          const url = window.URL.createObjectURL(dataBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        }
      }
    };

    const navigateCalender = (_workOrderId, _idPatient, _id_company) => {

      let _start_date = start_date
      let _end_date = end_date

     history.push(`../visualizador/${_start_date}/${_end_date}/calender`);

  }

  //*CONFIGURATION BUTTONS
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
        setIsHovered(true);
    };

  const handleMouseLeave = () => {
        setIsHovered(false);
  };

  // ? CONFIGURATION COMPLEMENT PAYMENT
  const handleViewDetalil = () => {
    setIsComplement(true) 
    console.log("entra");
    

  }

  const handleClosedViewDetalil = () => {
    setIsComplement(false)

  }

    




    //*VALIDATION SEND RECEIVED
    const validationReceived = () => {
      let statusValidation = false;
      let newValidations = {};

      if (formQuery.amount === 0 || formQuery.amount === "" ) {
          newValidations = {
              ...newValidations,
              amount: true,
          };

          statusValidation = true;
      }
      else {
          newValidations = {
              ...newValidations,
              amount: false,
          };
      }

      if (formQuery.received === "") {
        newValidations = {
            ...newValidations,
            received: true,
        };

        statusValidation = true;
       }
      else {
          newValidations = {
              ...newValidations,
              received: false,
          };
      }

        if (formQuery.delivere === "") {
          newValidations = {
              ...newValidations,
              delivere: true,
          };

          statusValidation = true;
      }
      else {
          newValidations = {
              ...newValidations,
              delivere: false,
          };
      }
      setvalidationsSG(newValidations);
      return statusValidation;
  }

     // * FORMAT NUMBERS
     const number_format = (cantidad, decimals) => {
      cantidad += '';
      cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

      decimals = decimals || 0;

      if (isNaN(cantidad) || cantidad === 0) {
          return parseFloat(0).toFixed(decimals);
      }

      cantidad = '' + cantidad.toFixed(decimals);

      let cantidad_parts = cantidad.split('.'),
          regexp = /(\d+)(\d{3})/;

      while (regexp.test(cantidad_parts[0]))
          cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

      return cantidad_parts.join('.');
    }


    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 404) {
          createSweet("warning", "warning", nameMethod, "No se ha realizado ningún deposito bancario en este dia");
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, Code500);
        }
        else {
            createSweet("warning", "warning", nameMethod, response?.data?.msg);
        }

        return null;
    }


    return {
       //*FILTER DAY
       start_date, end_date,handleInputChange,

       //*FORMVALUES DEPOSITS
       received,delivere,amount,observation, date_1,date_2,one,range,

       //*METHODS 
       GetReportDeposits,listMovements,indicatorAmount,indicatorCancell,indicatorPatient,indicatorAmountInvoice,
       GetReportPublic,listMovementsCalcule,

       //*CHART BANKING
       series,CurrentlyDeposits2,listMovementsInvoice,

       //* FORMAT NUMBERS
       number_format,

       //*LOADINGS
       loading,

       //*VARIABLES LOCAL STORAGE
       _user,

       //*SAVE CALCULATE RECEIVED
       saveReceived,disabledSaveButton,
     
       //*VALIDATION
       validationsSG,

       //*ONCHANGE CALENDER
       date,onChange,validNotification,selectedDate,tileClassName,

       //*VIEW RECEIVED
       GetDeposits,listReceived,

       //*VIEW CREDIT, FULLY PAID
       Monthlysales,indicadorFullyPaid,series2,ChartPaymentConditions,
       ChartPaymentCancell,indicadorCredit,handleChangeTypeAction,type_action, listFilter,
       
       //*VIEW INVOICE PUBLIC GENERAL AND GLOBAL
       countPublic,countGeneral,dataFilter,GetFilterInvoices,

       //*FILTER PAY METHOD
       filterEfectivo, setFilterEfectivo,
       filterTC,setFilterTC,
       filterTD,setFilterTD,
       filterTransferencias, setFilterTransferencias,

       //*EXPORT EXCEL
       MakeExcelInvoices,

       //*FILTER COMPANY
       type_company,handleTypeCompany,totalDepositSend,

       //*DETAIL BRANCH
       listDetailBranch,brach,
       listDetailBranchInvoice,

       //*CHART BRACH
       ChartPaymentConditionsTable,

       //*SIZE CARDS
       sizeCard,toggleSize,

       //*CHART AND INFO DEPOSITS
       amountDesposit,arrayDeposits, ChartPaymentConditionsTable1,

       handleActions,amountFinance,_validationFinance,
       detailBranch,loadingFinance,MakeExcelFinance,navigateCalender,
       _valid_Finance,

      //*CONFIGURATION BUTTONS
      isHovered,handleMouseEnter,handleMouseLeave,

      // ? PAYMENT
      dataComplement,totalComplement,handleViewDetalil,isComplement,handleClosedViewDetalil



    }

}
